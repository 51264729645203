import { Button, Chip, HSpacer, Text, TextBadge } from '@/components/DesignSystem';
import ViewRewardsDialog from '@/pages/Admin/FarmerList/ViewRewardsDialog';
import { getUserDisplayName } from '@/utilities/Farmer';
import { ApiUser } from '@api/interfaces';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material';
import React, { useState } from 'react';
import AddRewardsDialog from './AddRewardsDialog';
import FarmerDetails from './FarmerDetails';
import './styles.css';
import { AccountGroupCustomerRole } from "@shared/enums";

type FarmerListItemProps = {
  farmer: ApiUser;
};
const FarmerListItem = ({ farmer }: FarmerListItemProps) => {


  const [showAddModal, setShowAddModal] = useState(false);
  const [showRewardsDialog, setShowRewardsDialog] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const customerRole = farmer.groupCustomer?.customerRole;

  return (
    <Accordion disableGutters expanded={expanded} sx={{ padding: '28px' }}>
      <AccordionSummary
      className='accordion'
      expandIcon={<ExpandMore onClick={() => setExpanded(!expanded)} />}
      sx={{ padding: '0px' }}
      >
        <Stack alignItems='center' direction='row' justifyContent='space-between' width='100%'>
          <Stack direction="row">
            <Text category='h6'>{getUserDisplayName(farmer)}</Text>
            {farmer.isTest &&
              <>
                <HSpacer size="5" />
                <Chip color="primary" label="Test User" testID="test-user-chip" />
              </>
            }
            {customerRole &&
              <>
                <HSpacer size="5" />
                <TextBadge
                  backgroundColor={customerRole === AccountGroupCustomerRole.Secondary
                    ? '#B2F2F0'
                    : '#E5D1FF'
                  }
                  text={`${customerRole.toUpperCase()} ACCOUNT`}
                  textColor="black"
                />
              </>
            }
          </Stack>
          <Stack alignItems={'center'} direction='row' mr={'16px'} spacing={2}>
            <Button
              onClick={() => {
                setShowRewardsDialog(true);
              }}
              testID='view-rewards-button'
              variant='outlined'
            >
              View Rewards
            </Button>
            {(!customerRole || customerRole === AccountGroupCustomerRole.Primary) && (
              <Button
                onClick={() => {
                  setShowAddModal(true);
                }}
                testID='add-rewards-button'
                variant='outlined'
              >
                Add Rewards
              </Button>
            )}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>{expanded && <FarmerDetails farmer={farmer} />}</AccordionDetails>
      {showAddModal && (
        <AddRewardsDialog
          farmer={farmer}
          setShow={setShowAddModal}
          show={showAddModal}
        />
      )}
      {showRewardsDialog &&
        <ViewRewardsDialog
          setShow={setShowRewardsDialog}
          show={showRewardsDialog}
          userId={farmer.id}
        />
      }
    </Accordion>
  );
};

export default FarmerListItem;
