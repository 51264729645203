import { Divider, Stack, useTheme } from '@mui/material';
import { DashboardModal } from '@/pages/CustomerDashboard/DashboardModal';
import { Text, VSpacer } from '@/components/DesignSystem';
import { formatDate } from '@/pages/CustomerDashboard/helpers';
import { getJsDateFromString } from '@shared/utilities';
import React from 'react';
import { ResolvedPromotion } from '@/pages/CustomerDashboard/interfaces';

interface PromotionDetailsModalProps {
  onClose: () => void,
  promotion: ResolvedPromotion,
  retailerName: string,
}

export const PromotionDetailsModal = ({
  onClose,
  promotion,
  retailerName,
}: PromotionDetailsModalProps) => {
  const theme = useTheme();

  const productNames = promotion.products.map(({ name }) => name);
  const locationNames = promotion.locations.map(({ name }) => name);
  const formattedValue = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  }).format(promotion.value * 100);

  return (
    <DashboardModal
      onClose={onClose}
      open
      testID="promotion-details-modal"
    >
      <Stack flexGrow={1} overflow="auto" px="16px">
        <Stack alignItems="center">
          <Text category="title-large">
            Offer details
          </Text>
        </Stack>
        <VSpacer size="5"/>
        <Text category="overline" sx={{ color: theme.palette.grey[800] }}>
          VALID THRU {formatDate(getJsDateFromString(promotion.endDate))}
        </Text>
        <VSpacer size="2"/>
        <Text fontSize="22px" fontWeight={500}>
          {promotion.name}
        </Text>
        <VSpacer size="3"/>
        <Text category="body-medium">
          {formattedValue}% points earned
        </Text>
        <VSpacer size="4"/>
        <Text category="body-medium">
          {promotion.description}
        </Text>
        <VSpacer size="6"/>
        <Divider light/>
        <VSpacer size="6"/>
        <Text fontSize="16px" fontWeight={500}>
          Participating products
        </Text>
        <VSpacer size="2"/>
        <Text category="body-medium">
          {productNames.join(', ')}
        </Text>
        <VSpacer size="6"/>
        <Divider light/>
        <VSpacer size="6"/>
        <Text fontSize="16px" fontWeight={500}>
          {retailerName} locations
        </Text>
        <VSpacer size="2"/>
        <Text category="body-medium">
          {locationNames.join(', ')}
        </Text>
        <VSpacer size="6"/>
        <Divider light/>
      </Stack>
    </DashboardModal>
  );
};